@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@layer components {
    .btn-primary {
        @apply bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-36 rounded-lg;
    }

    .btn-secondary {
        @apply bg-h2v-orange font-poppins text-base text-white font-medium py-2 px-4 w-36 rounded-lg;
    }
}

@layer scrollbar {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}